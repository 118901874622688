.App {
  text-align: center;
}

.Homepage-logo {
  height: 40vmin;
  pointer-events: none;
}

.Homepage-img {
  width: 85%;
}

.Homepage > .bio {
  /* width: calc(800px + 2vmin); */
  font-size: calc(5px + 2vmin);
  line-height: calc(15px + 2vmin);
  padding: 0 1.5em;
}

a {
  text-decoration: none;
  color: rgb(181, 180, 180);
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .Homepage-logo {
    animation: Homepage-logo-spin infinite 20s linear;
  }
}

.App {
  /* background-color: #282c34; */
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  line-height: calc(10px + 2vmin);
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  color: black;
  margin: 50px calc(50px + 2vmin);
  border: 2px solid #ddd;
  border-bottom: none;
}

.App-jack {
  margin-top: 100px;
  font-size: calc(20px + 2vmin);
}

@keyframes Homepage-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.NavBar-divider {
  border-bottom: 1px solid #ddd;
  font-size: 1px;
  /* line-height: 0.6em;
  margin-top: -0.6em;
  margin-bottom: 0.6em; */
}

/* nav */
.NavBar {
  /* right: 0; */
  color: #282c34;
  z-index: 99;
  margin: calc(60px + 1vmin);
  /* border-bottom: 1px solid black; */
  /* background: #282c34; */
}
.NavLink {
  display: inline-block;
  margin: 15px 50px;
}
.NavLink > a {
  color: #282c34;
  text-decoration: none;
}

.prof-pic-div {
  float: left;
}

.contact-blurb {
  width: 90%;
}
.funny-blurb {
  width: 90%;
  font-size: 12px;
}
.homepage-disclaimer-container {
  margin-top: 45px;
}
.homepage-disclaimer {
  font-size: 10px;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.error-email {
  border: 1px solid red;
}

.textarea {
  max-width: 50%;
  height: 50%;
  width: 50%;
  max-height: 50%;
  margin-top: 10px;
}

.contact-form {
  width: 90%;
}

#prof-pic-img {
  border-radius: 150px;
}

/* @keyframes slide-profile-pic {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-35vw, -30vh);
  }
} */

li {
  font-size: calc(5px + 2vmin);
  line-height: calc(15px + 2vmin);
}
.Resume {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Projects {
  width: 100%;
}

.project-type-container {
  margin-bottom: 75px;
}

.projects-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.project-container {
  margin: 15px 30px;
  border: 1px solid #151617;
  padding: 25px;
  height: 175px;
  overflow-y: scroll;
  width: calc(20px + 55vmin);
}

.project-container > .description {
  line-height: 1.25;
}

.project-stack {
  margin: 0;
  padding: 3px;
}

.project-title {
  font-size:  calc(10px + 2vmin);
  margin: 2px;
}
.project-non-title {
  font-size:  calc(5px + 2vmin);
}

.stack-title {
  margin: 5px;
}

.ResumeTile {
  margin: 15px 30px;
  border: 1px solid #151617;
  padding: 25px;
  height: 175px;
  overflow-y: scroll;
  width: calc(20px + 35vmin);
}

.resume-disclaimer {
  font-size: 15px;
  margin-top: 0;
  font-style: italic;
  width: 95%;
}

.hidden {
  visibility: hidden;
}
.disabled {
  opacity: 0.5;
}

.resume-company a,
a.project-link {
  color: #59599d;;
}

.resume-dates {
  font-size: calc(7px + 2vmin);
  font-style: italic;
  color: rgb(181, 180, 180);
}

.show-more {
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
}

.project-video {
  width: 12em;
}